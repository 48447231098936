//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { GuidePermissions } from '@/modules/guide/guide-permissions';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'app-guide-view-toolbar',

  data() {
    return {
      
    }
  },
  computed: { 
    ...mapGetters({
      record: 'guide/view/record',
      loading: 'guide/view/loading',

      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    hasPermissionToEdit() {
      return new GuidePermissions(this.currentUser).edit;
    },
  },

  methods: {
    ...mapActions({
      doToggleStatus: 'guide/view/doToggleStatus',
    }),
  },
};
