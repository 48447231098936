//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';

import GuideViewToolbar from '@/modules/guide/components/guide-view-toolbar.vue';
import ReviewListCard from '@/shared/components/review/review-list-card.vue';
import TransactionListCard from '@/modules/transaction/components/partial/transaction-list-card.vue';
import LoadingGuideInfo from '@/modules/guide/components/loading-guide-info.vue';

import vueI18n from '@/vueI18n'
import { i18n } from '@/vueI18n';
import moment from 'moment';

// import firebase from 'firebase/compat/app';
// import 'firebase/compat/firestore'
// const db = firebase.firestore();

import Roles from '@/security/roles';
import { GuideModel } from '@/modules/guide/guide-model';
const { fields } = GuideModel;

export default {
  name: 'app-guide-view-page',
  // props: ['id'],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  
  components: {
    [GuideViewToolbar.name]: GuideViewToolbar,
    [ReviewListCard.name]: ReviewListCard,
    [TransactionListCard.name]: TransactionListCard,
    [LoadingGuideInfo.name]: LoadingGuideInfo,
  },
  
  async mounted() {
    await this.doFindByVuexfire(this.id);
    // await this.doFind(this.id);
    // this.fullName = this.record.fullName
    // this.totalRating = this.record.rate
    // this.totalAmount = this.record.wallet
  },

  data() {
    return {
      dialog: false,
      model: null,
      totalRating: 0,
      totalAmount: 0,
      fullName: undefined,
    };
  },
  computed: {
    ...mapGetters({
      record: 'guide/view/record',
      loading: 'guide/view/loading',
      toggleStatusloading: 'iam/view/statusLoading',

      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    fields() {
      return fields;
    },
    language() {
      return vueI18n.locale
    },
  },
  // firestore() {
  //   return {
  //     record: db.collection('user').doc(this.id),
  //   }
  // },
  watch: {
    record(val) {
      if (val) {
        this.fullName = this.record.fullName
        this.totalRating = this.record.rate
        this.totalAmount = this.record.wallet
      }
    }
  },
  methods: {
    ...mapActions({
      doFind: 'guide/view/doFind',
      doFindByVuexfire: 'guide/view/doFindByVuexfire',
      doToggleUserAccountStatus: 'iam/view/doToggleUserAccountStatus'
    }),
    i18n(key, args) {
      // return this.$t(key, args);
      return i18n(key, args);
    },
    roleLabelOf(roleId) {
      return Roles.labelOf(roleId);
    },
    presenter(row, fieldName) {
      const val = GuideModel.presenter(row, fieldName);
      return val ? val : 'ـــ';
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      return val.length ? val[0].label : 'ـــ';
    },
    presenterMap(row, fieldName) {
      const val = GuideModel.presenter(row, fieldName);
      return val[this.currentLanguageCode] ? val[this.currentLanguageCode] : 'ـــ'; 
    },
    presenterDateTime(val) {
      // const val = GuideModel.presenter(row, fieldName);
      // return val ? moment(val).locale(this.currentLanguageCode).format("dddd D MMM, YYYY h:mm A") : 'ـــ'; 
      return val ? moment(val).locale(this.currentLanguageCode).format("dddd D MMM, YYYY") + this.i18n('common.at') + moment(val).locale(this.currentLanguageCode).format("hh:mm A") : 'ـــ';
    },
    presenterDate(val) {
      // const val = GuideModel.presenter(row, fieldName)
      return val ? moment(val).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــــــ'; 
    },
    presenterDay(row, fieldName) {
      return moment(GuideModel.presenter(row, fieldName)).locale(this.currentLanguageCode).format("dddd");
    },
    presenterTime(row, fieldName) {
      // let now = moment().locale('en').format('YYYY/MM/DD');
      // let time = GuideModel.presenter(row, fieldName)
      let n = row[fieldName]

      // console.log();
      // let date =  `${now} ${time}`;
      return moment(n,"YYYY-MM-DD'T'hh:mm:ss.SSS'Z'").locale(this.currentLanguageCode).format('hh:mm a')
    },
    presenterUser(row, fieldName, key) {
      const user = GuideModel.presenter(row, fieldName);
      if (key == 'avatars' || key == 'avatar') {
        let avatar = null
        if (user && Array.isArray(user[key])) {
          avatar = user[key].length ? user[key][0].publicUrl : null
        } else if (user && user[key]) {
          avatar = user[key].publicUrl 
        }
        return avatar ? avatar : 'https://firebasestorage.googleapis.com/v0/b/puricom-beebe.appspot.com/o/public%2Fdefault%2Favatar.svg?alt=media&token=439fb6e6-815f-453e-9e3a-79dc0ab3d40c'
      }
      return user && user[key] ? user[key] : 'ـــ'; 
    },
    presenterClient(row, fieldName, key) {
      const val = GuideModel.presenter(row, fieldName);
      return val && val[key] ? val[key] : 'ـــ'; 
    },
    presenterLocalization(data) {
      if (typeof data === 'object' && data !== null) {
        return data[this.currentLanguageCode] ? data[this.currentLanguageCode] : data['en']
        // if (this.currentLanguageCode in data) {
        //   return data[this.currentLanguageCode]
        // }
      }
      return  
    },
    toggleAccountStatus(id) {
      console.log(id);
      this.doToggleUserAccountStatus(id)
    },
  },
};
